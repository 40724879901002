<template>
  <el-menu
    style="display: inline-block"
    mode="horizontal"
    router
    :default-active="$route.path"
  >

    <MenuItem
      index="/"
      :route="{name: 'Home'}"
    >
      Главная
    </MenuItem>

    <el-submenu
      v-show="showAdministration"
      index="1"
    >
      <template slot="title">Администрирование</template>

      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.RBAC.LIST)"
        index="/settings/rbac"
        :route="{name: 'Rbac'}"
      >
        RBAC
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.USER.CHIEFS_LIST_BY_TYPE)"
        index="/settings/chiefs"
        :route="{name: 'Chiefs'}"
      >
        Оргструктура
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.CONFIGURABLE_SCALES.SEE_IN_MENU)"
        index="/settings/scales"
        :route="{name: 'Scales'}"
      >
        Шкалы
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.GROUP.LIST)"
        index="/settings/groups"
        :route="{name: 'Groups'}"
      >
        Группы
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.MASS_ACTIONS.CREATE)"
        index="/settings/mass-actions"
        :route="{name: 'MassActions'}"
      >
        Массовые действия
      </MenuItem>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"
        index="/settings/dynamic"
        :route="{name: 'DynamicSettings'}"
      >
        Настройки
      </MenuItem>
    </el-submenu>


<!--    <el-submenu-->
<!--      v-show="$canAndRulesPass(user, $permissions.LIBRARY.SEE_IN_MENU)"-->
<!--      index="2"-->
<!--    >-->
<!--      <template slot="title">Библиотеки</template>-->

<!--      <MenuItem-->
<!--        v-for="(typeConfig, typeKey) in $companyConfiguration.libraries.getTypes()"-->
<!--        :key="typeKey"-->
<!--        :index="'/library/'+typeKey"-->
<!--        :route="{name: 'Library', params: { type: typeKey }}"-->
<!--      >-->
<!--        {{ typeConfig.showName }}-->
<!--      </MenuItem>-->
<!--    </el-submenu>-->

    <MenuItem
      v-show="$canAndRulesPass(user, $permissions.CARD.LIST)"
      index="/cards"
      :route="{name: 'Cards'}"
    >
      Карты
    </MenuItem>

    <MenuItem
      v-show="$canAndRulesPass(user, 'card.abcd_list_see')"
      index="/card-points-list"
      :route="{name: 'CardPointsList'}"
    >
       ABCD свод
    </MenuItem>
<!--    <MenuItem-->
<!--      v-show="$canAndRulesPass(user, $permissions.MATRIX.VIEW)"-->
<!--      index="/matrix"-->
<!--      :route="{name: 'Matrix'}"-->
<!--    >-->
<!--      Матрица КПЭ-->
<!--    </MenuItem>-->
<!--    <MenuItem-->
<!--      v-show="$canAndRulesPass(user, $permissions.USER.ORGSTRUCT_VIEW)"-->
<!--      index="/orgstruct"-->
<!--      :route="{name: 'Orgstruct'}"-->
<!--    >-->
<!--      Орг. структура-->
<!--    </MenuItem>-->
    <MenuItem
      v-show="$canAndRulesPass(user, $permissions.REPORT.USE)"
      index="/report"
      :route="{name: 'Report'}"
    >
      Конструктор отчетов
    </MenuItem>

  </el-menu>
</template>

<script>
import {mapGetters} from "vuex";
import MenuItem from "@/components/header/MenuItem.vue";

export default {
  name: "menu-component",
  components: {MenuItem},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
    showAdministration(){
      return this.$canAndRulesPass(this.user, this.$permissions.RBAC.LIST)
          || this.$canAndRulesPass(this.user, this.$permissions.USER.CHIEFS_LIST_BY_TYPE)
          || this.$canAndRulesPass(this.user, 'setting.scales')
          || this.$canAndRulesPass(this.user, this.$permissions.GROUP.LIST)
          || this.$canAndRulesPass(this.user, this.$permissions.MASS_ACTIONS.CREATE)
          || this.$canAndRulesPass(this.user, this.$permissions.SETTING.UPDATE)
    }
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>


<style lang="scss">

</style>